<script setup lang="ts">
import { ref } from 'vue';

// Data for campaigns
const campaigns = ref([
  {
    title: "Fine Art MFERS",
    description: "Holders Can Claim a Custom NFT",
    imageUrl: require('@/assets/imgs/mfer.png'),
    link: "https://artpocalypse.fineartmfers.io/"
  },
  {
    title: "Raffl3",
    description: "Create Raffles and Invite Your Communities to Enter",
    imageUrl: require('@/assets/imgs/raffle.png'),
    link: "/raffle-home"
  },
  {
    title: "RaffleCast",
    description: "Raffles in Frames for projects Ethereum and Base",
    imageUrl: require('@/assets/imgs/RaffleCast.png'),
    link: "https://rafflecast.xyz/"
  },
  // Add more campaigns as needed
]);
</script>


<template>
  <div id="campaigns" class="flex items-center justify-center">
    <Card class="w-full max-w-[1400px] border-0">
      <CardHeader class="flex flex-col  gap-4 text-center">
        <CardTitle class="text-3xl">Active Campaigns</CardTitle>
        <CardDescription>Confirm digital ownership. Reward your community.</CardDescription>
      </CardHeader>
      <CardContent>
        <div class="grid p-10 w-full max-w-[55rem] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto">
          <!-- Dynamic Campaign Cards -->
          <a v-for="(campaign, index) in campaigns" :key="index" :href="campaign.link" target="_blank"
             class="hover:opacity-85 transition-opacity duration-100 ease-in">
            <Card class="flex h-full justify-between flex-col items-center bg-white/90 border-[#ffffff50] hover:scale-95 hover:transition-all hover:opacity-90 rounded-xl overflow-hidden">
              <figure class="w-full flex flex-col text-center">
                <img :src="campaign.imageUrl" alt="Campaign Image" class=" mx-auto"/>
                <h5 class="animatedText bold text-xl py-2 ">{{ campaign.title }}</h5>
              </figure>
              <div class="p-2 text-center justify-start">
               
                <p class="text-sm p-2 text-black">{{ campaign.description }}</p>
              </div>
            </Card>
          </a>
        </div>
      </CardContent>
      <CardFooter class="pb-20 ">
        <!-- <span class="text-sm">Streamline ownership tracking and reward distribution with <strong>NFT Redeem</strong>. Effortlessly set up claimable reward campaigns for your community. -->
          <span class="p-2 px-8 bg-black/50 rounded-full"> Get in touch with us at <a href="mailto:info@metavate.io"
            class="text-pink-300">info@metavate.io</a></span>
         
        <!-- </span> -->
      </CardFooter>
    </Card>
  </div>
</template>

<style scoped>
/* Add any additional custom styles if needed */
</style>
