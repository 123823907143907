<script setup lang="ts">
// import { Button } from '../../components/ui/button'; // Adjust the import path as per your project structure

// const navigateToRaffleHome = () => {
//   window.location.href = '/#campaigns';
// };

// Array of tile data with lists for descriptions
const tiles = [
  {
    title: "NFT Redeem",
    description: [
      "Validate ownership",
      "Distribute claimable rewards",
      "Assign rewards for a specific token id or an entire collection",
      "Provide exclusive content, physical goods, digital assets, real-world perks",
      "Create on-going or seasonal reward campaigns",
      "Ensure eligible community members have access to benefits that come with their token"
    ],
    link: "https://metavate.io/#contact",
    buttonText: 'Contact Us'
  },
  {
    title: "Raffl3",
    description: [
      "Token-gated raffles",
      "Spin-up and easily share raffles",
      "Validate holder eligibility",
      "Ideal situation: You only have a few prizes or a single prize to give away",
      "Drive participation and interest in a project",
      "Provide a fair chance to win for all eligible holders"
    ],
    link: "./raffle-home",
    buttonText: 'Try it Now'
  },
  {
    title: "RaffleCast",
    description: [
      "Bring raffle experiences into Farcaster",
      "Create token-gated raffles as frames",
      "Share on Warpcast or your fav Farcaster client",
      "Participants enter without additionally connecting a wallet",
      "Provide a frictionless entry process"
    ],
    link: "https://rafflecast.xyz",
    buttonText: 'Try it Now'
  }
];
</script>

<template>
  <div class="w-full max-w-[100rem] my-10 py-10 px-4 gap-4 flex flex-col mx-auto">
    <div class="flex flex-col text-white">
      <h2 class="text-2xl md:text-5xl">
        Engage Your Community <br/> with Rewards
      </h2>
      <p class="my-8">Explore these different offerings  </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto">
      <div 
        v-for="(tile, index) in tiles" 
        :key="index" 
        class="p-4 px-8 bg-white/90 text-black text-left rounded-xl shadow-lg flex flex-col justify-between">
        <div>
          <h3 class="text-xl text-center my-8 font-bold mb-2">{{ tile.title }}</h3>
          <ul class="list-disc list-inside">
            <li v-for="(point, i) in tile.description" :key="i">{{ point }}</li>
          </ul>
        </div>
        <br/>
        <a v-if="tile.buttonText" :href="tile.link" class="p-2 bg-violet-700 text-white w-full mt-8 text-center">
          {{ tile.buttonText }}
        </a>
        <a v-else :href="tile.link" class="p-2 bg-violet-700 text-white w-full mt-8 text-center">
          Learn more
        </a>
      </div>
    </div>
    
    <!-- <Button @click="navigateToRaffleHome" variant="default" class="bg-violet-400 hover:bg-white hover:text-black w-48 mx-auto">
      View All Campaigns
    </Button> -->
  </div>
</template>

<style scoped>
/* Add any additional styling here if needed */
</style>
